import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { EventBus } from "@/event-bus";
import { fnsBasicDate } from "@/filters/date-fns.filter";
import {
  BatchSample,
  InventoryBatch,
  LaboratoryResult,
  potencyModel
} from "@/interfaces/batch";
import { i18n } from "@/plugins/i18n";
import { labResultsService } from "@/services/labResults.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { traceabilityService } from "@/services/traceability.service";
import {
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableSubListComponent,
  TableSuccessModalResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { all, create } from "mathjs";
import { Field } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import TraceabilityConfirm from "../../../sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import ModifyResultsModalComponent from "./modifyResults/ModifyResults.component";
import Template from "./PotencyTerpenes.template.vue";

const namespace = "BatchModule";

const math = create(all, {});

const stringResults = (gramVal: number, perVal: number) => {
  const results = [];
  if (Number(gramVal)) {
    results.push(`${+parseFloat(String(gramVal)).toFixed(3)} mg/g`);
  }
  if (Number(perVal)) {
    results.push(`${+parseFloat(String(perVal)).toFixed(3)} %`);
  }
  if (results.length) {
    return results.join(" - ");
  }
  return 0;
};
/**
 * batch basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    TableSubListComponent,
    ModifyResultsModalComponent,
    TableComponent,
    ChoiceModal
  },
  filters: { fnsBasicDate }
})
export default class PotencyTerpenesComponent extends Vue {
  public get labResults(): LaboratoryResult[] {
    const labRes =
      (this.batchSamples && cloneDeep(this.batchSamples.results)) || [];

    for (const lab of labRes) {
      lab.laboratory = (this.batchSamples && this.batchSamples.laboratory) || {
        testing_date: ""
      };
      lab.laboratory!.testing_date =
        (this.batchSamples && this.batchSamples.testing_date) || "";
    }

    return labRes;
  }

  public get disableUpload() {
    return (
      !this.batchSamples ||
      (this.batchSamples && !this.batchSamples.results.length)
    );
  }

  public get terpeneTotal(): string {
    let gramVal = 0;
    let perVal = 0;
    if (this.batchSamples) {
      if (this.batchSamples!.results.length) {
        this.batchSamples!.results.forEach(batchIndex => {
          if (batchIndex.profile!.breakdown === "TERPENE") {
            batchIndex.unit_representation === "%"
              ? (perVal = perVal + +batchIndex.value!)
              : (gramVal = gramVal + +batchIndex.value!);
          }
        });
        return String(stringResults(gramVal, perVal));
      } else {
        return String(
          stringResults(
            this.batchSamples.terpene,
            this.batchSamples.terpene_relative!
          )
        );
      }
    }
    return "0";
  }
  /**
   * model to save
   * @var Batch batch
   */
  @Prop({ required: true })
  public batch!: InventoryBatch;
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ required: true })
  public batchSamples!: BatchSample | null;
  @Prop({ required: true })
  public onDisplay!: boolean;
  @Prop({ default: false })
  public hasModifyPermission!: boolean;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public pendingFiles: File[] = [];
  public loadingAttachments: boolean = false;
  public attachmentShow: File[] = [];
  public hasResults = false;
  public isStatusTestingType = "";
  public loader: boolean = false;
  public loadingSamples = false;
  public finishThcCb = "0";
  public model = {
    ...potencyModel,
    notes: [
      {
        name: "new potency note",
        title: "title",
        note: ""
      }
    ],
    contacts: [
      {
        name: "",
        phone: "",
        email: ""
      }
    ]
  };
  public fileOptions = {
    multiple: false,
    accept: "image/*,.pdf"
  };

  public fileHeaders: TableHeader[] = [
    {
      label: i18n.t("file_name").toString(),
      value: "attachment_file_name",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    },
    {
      label: i18n.t("date").toString(),
      value: "created_at",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.fnsDate
    },
    {
      label: i18n.t("uploaded_by").toString(),
      value: "created_by.name",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    }
  ];

  public fileActions: TableAction[] = [
    {
      icon: "fas fa-eye",
      action: (...arg: Array<{ attachment_medium_url: string }>) => {
        const mediumUrl = arg[0].attachment_medium_url;
        window.open(mediumUrl, "_blank");
      }
    },
    {
      icon: "fal fa-trash-alt",
      modalActions: {
        modalNumber: 1,
        modalQuestion: i18n.t("attachment_delete_confirm").toString(),
        modalSuccessText: "yes",
        modalSuccessAction: (arg: TableSuccessModalResponse) => {
          this.deleteFile(arg.item as { id: number });
          arg.unselectModal();
        },
        modalCancelText: "no"
      }
    }
  ];

  public tableColors: string[] = [
    "#ffffff",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  protected edit!: boolean;

  private potencyTests: LaboratoryResult[] = [];
  private terpeneTests: LaboratoryResult[] = [];
  private heavyMetalsTests: LaboratoryResult[] = [];
  private otherTests: any[] = [];
  private globalTH: number = 1;
  private globalCB: number = 1;
  private similarTestGroup = {};

  public removePendingFile(index: number) {
    this.pendingFiles.splice(index, 1);
    this.$emit("filesChanged", this.pendingFiles);
  }

  public onFileChange(e: any) {
    const files = e.target.files;
    // @ts-ignore
    this.pendingFiles = this.getFileNames(files);
    this.$emit("filesChanged", this.pendingFiles);
  }
  public isFormValid(): boolean {
    return (
      this.$validator.errors.any() ||
      (!!this.$validator.fields.items.filter(
        (f: Field) => f.flags.untouched && f.flags.required
      ).length &&
        !this.edit)
    );
  }

  public async popUp(
    titleName: string,
    messageName: string,
    descriptionName: string,
    loadingName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue: string
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: this.$t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },

            loading: this.$t(loadingName),
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    if (!confirm) {
      return;
    }
  }

  public async syncPopUpCallback() {
    if (await this.$validator.validateAll()) {
      this.popUp(
        "biotrack_traceability.inventory_/_batch_details",
        "biotrack_traceability.loading_testing_results",
        "biotrack_traceability.loading_testing_results_from_biotrack_traceability",
        "audit.loading",
        false,
        false,
        ""
      );
      const syncTestResults = await traceabilityService.getSyncTestResults(
        this.batch!.batch_uid
      );
      EventBus.$emit("removePopup", true);
      if (syncTestResults.status === "success") {
        this.popUp(
          "biotrack_traceability.inventory_/_batch_details",
          "biotrack_traceability.testing_results_successful",
          "biotrack_traceability.syncing_testing_results_with_biotrack_traceability_successful",
          "",
          true,
          false,
          "ok"
        );
        this.getSamples();
        this.loader = true;
      }
      if (syncTestResults.status === "error") {
        const message = syncTestResults.data
          ? syncTestResults.data
          : syncTestResults.error.message;
        // When No result will come from trace , we need to handle the case by changing message
        const isNoResult =
          message.includes(
            "There were no test results available for the batch"
          ) ||
          message.includes(
            "No hubo resultados de pruebas disponibles para el lote"
          );
        this.popUp(
          "biotrack_traceability.inventory_/_batch_details",
          isNoResult
            ? "biotrack_traceability.testing_results_not_found"
            : "biotrack_traceability.testing_results_failed",
          isNoResult
            ? message
            : i18n.t(
                "biotrack_traceability.syncing_testing_results_with_biotrack_traceability_failed"
              ) + `${message}`,
          "",
          true,
          false,
          "ok"
        );
      }
    }
  }

  public modalToggle() {
    this.$modals
      .load<{ changed: boolean; samples: BatchSample }>(
        ModifyResultsModalComponent,
        {
          size: "normal",
          positionY: "top",
          positionX: "center",
          style: "max-height : 100%; max-width : 45%"
        },
        {
          batchSamples: this.batchSamples
        }
      )
      .then(data => {
        if (data.changed) {
          this.$emit("updatedResults", {
            hasResults: this.hasResults,
            samples: data.samples
          });
        }
      })
      .catch(() => {
        // No action on close.
      });
  }

  public async getSamples() {
    if (this.batch && !this.loadingSamples) {
      this.loadingSamples = true;
      this.batchSamples = await labResultsService.getBatchSamples(
        this.batch.batch_uid
      );
      this.loadingSamples = false;
    }
  }

  public importLabResults() {
    this.$router.push({
      name: "import-wizard",
      params: { type: "lab-results" }
    });
  }
  public formatResult(value: number) {
    return +parseFloat(String(value)).toFixed(3);
  }
  public parseTests() {
    this.potencyTests = [];
    this.terpeneTests = [];
    this.heavyMetalsTests = [];
    this.otherTests = [];
    if (this.labResults.length === 0) {
      this.finishThcCb = "0";
    }
    this.labResults.map((element: LaboratoryResult) => {
      if (!element._destroy) {
        if (element.profile!.name === "THC") {
          this.globalTH = +element.value!;
        }
        if (element.profile!.name === "CBD") {
          this.globalCB = +element.value!;
        }
        if (
          this.labResults.length &&
          (element.profile!.name === "THC" || element.profile!.name === "CBD")
        ) {
          this.finishThcCb = "0";
        }

        if (element.profile!.breakdown === "POTENCY") {
          this.potencyTests.push(element);
        } else if (element.profile!.breakdown === "TERPENE") {
          this.terpeneTests.push(element);
        } else {
          const otherTestArray = {
            breakdown: element
              .profile!.breakdown!.replace(/_/g, " ")
              .toLowerCase()
              .split(" ")
              .map(word => {
                return word.replace(word[0], word[0].toUpperCase());
              })
              .join(" "),
            testType: element
          };
          this.otherTests.push(otherTestArray);
        }
      }
    });
    this.similarTestGroup = this.otherTests.reduce((r: any, acc: any) => {
      const { breakdown, testType } = acc;
      r[breakdown] = [
        ...(r[breakdown] || []),
        {
          breakdown,
          testType
        }
      ];
      return r;
    }, {});
    this.updateThcCbdRatio();
  }

  public async loadAttachments() {
    this.loadingAttachments = true;
    this.attachmentShow = await labResultsService.getAttachments(
      this.batch!.batch_uid
    );
    this.loadingAttachments = false;
  }
  protected async init() {
    if (this.batch && !this.batchSamples) {
      this.batchSamples = {
        thc: 0,
        cbd: 0,
        thc_thca: 0,
        cbd_cbda: 0,
        terpene: 0,
        results: [],
        laboratory_id: null
      };
    }
    this.loadAttachments();
  }
  protected mounted() {
    const unwatch = this.$watch("onDisplay", () => {
      if (this.onDisplay) {
        this.init();
        unwatch();
      }
    });
    this.$watch("batchSamples", async () => {
      if (this.batchSamples) {
        this.hasResults = !!(
          this.batchSamples && this.batchSamples.results.length
        );
        this.thcCbdRatio();
        this.parseTests();
      }
    });
  }
  protected getFileNames(files: FileList) {
    const filesArr = [];
    if (files.length) {
      for (const file of files) {
        filesArr.push({ attachment: file });
      }
    }
    return filesArr;
  }

  private async deleteFile(file: { id: number }) {
    await labResultsService.deleteAttachment(this.batch.batch_uid, file.id);
    this.loadAttachments();
  }

  public get totalThc(): string {
    if (this.batchSamples) {
      return String(
        stringResults(this.batchSamples.thc, this.batchSamples.thc_relative!)
      );
    }
    return "0";
  }

  public get totalCbd(): string {
    if (this.batchSamples) {
      return String(
        stringResults(this.batchSamples.cbd, this.batchSamples.cbd_relative!)
      );
    }
    return "0";
  }
  private async thcCbdRatio() {
    if (this.batchSamples && (this.globalCB || this.globalTH)) {
      try {
        const sumThc = this.batchSamples.thc + this.batchSamples.thc_relative!;
        const sumCbd = this.batchSamples.cbd + this.batchSamples.cbd_relative!;
        const fraction = math.fraction!(sumThc * 1000, sumCbd * 1000);

        this.finishThcCb = math.format!(fraction, {
          fraction: "ratio"
        }).replace("/", ":");
      } catch (e) {
        return "0";
      }
    }
  }

  private async updateThcCbdRatio() {
    if (this.batchSamples && this.globalCB) {
      const fraction = math.fraction!(
        this.globalTH! * 1000,
        this.globalCB! * 1000
      );
      const fractionFinish = math.format!(fraction, {
        fraction: "ratio"
      }).replace("/", ":");

      this.finishThcCb = fractionFinish;
    }
  }
}
